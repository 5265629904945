import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb gust d’avellana que té un capell de marge més o menys ondulat, color beix grisenc, que pot fer fins a quasi 7 cm de diàmetre. Les làmines són obtuses, lliures i presenten nombroses lamel·les de color blanquinós tornant-se beix amb l’edat. És freqüent també trobar làmines d’un color negrós a blau fosc. El peu s’aixampla cap a la base i de color blanquinós a la part superior i de color semblant al capell a la part inferior. Les espores són blanques en massa, triangulars, de 9-10 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      